import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Menu from "../components/menu-it";
import ArticlePageLayout from "../components/article-page";

import VisibilitySensor from "react-visibility-sensor-v2";
import { Helmet } from "react-helmet";

export default function ArticlePage({ data }) {
  const { title } = data.itYaml;
  const { description } = data.itYaml;
  const { date } = data.itYaml;
  const { link } = data.itYaml;
  const { alternativeLang } = data.itYaml;
  const { publicURL } = data.itYaml.cover;
  var schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://extra.ilsalice.org/it/" + link,
    },
    headline: { title },
    description: { description },
    image: "https://extra.ilsalice.org/it/" + publicURL,
    author: {
      "@type": "Organization",
      name: "ilSalice",
    },
    publisher: {
      "@type": "Organization",
      name: "ilSalice",
      logo: {
        "@type": "ImageObject",
        url: "https://extra.ilsalice.org/icons/ilsaliceEX.png",
      },
    },
    datePublished: { date },
  };
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "it",
        }}
      >
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href={"https://extra.ilsalice.org/it/" + link} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={"https://extra.ilsalice.org" + publicURL}
        />
        <meta
          property="og:url"
          content={"https://extra.ilsalice.org/it/" + link}
        />
        <meta property="og:type" content="article" />

        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        {alternativeLang !== "none" && (
          <link
            rel="alternate"
            href={"https://extra.ilsalice.org/" + alternativeLang}
            hreflang="en"
          ></link>
        )}
        {alternativeLang !== "none" && (
          <link
            rel="alternate"
            href={"https://extra.ilsalice.org/it/" + link}
            hreflang="it"
          ></link>
        )}
      </Helmet>

      <Menu></Menu>

      <ArticlePageLayout
        language="it"
        h1={data.itYaml.h1}
        cover={data.itYaml.cover}
        coveralt={data.itYaml.coveralt}
        subtitle={data.itYaml.subtitle}
        content={data.itYaml.content}
        category={data.itYaml.category}
        author={data.itYaml.author}
        date={data.itYaml.date}
        related={data.allItYaml.nodes}
        data={data}
      />
    </>
  );
}

export const query = graphql`
  query CreatePageIt($link: String, $category: String) {
    itYaml(link: { eq: $link }) {
      title
      link
      language
      h1
      description
      coveralt
      author
      date(formatString: "DD/MM/YYYY")
      subtitle
      alternativeLang
      cover {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1500
            aspectRatio: 3
            placeholder: DOMINANT_COLOR
          )
        }
      }
      content {
        url {
          childImageSharp {
            gatsbyImageData(width: 900)
          }
        }
        type
        text
        alt
        title
        description
        image
        link
        topic
        source
      }
    }
    allItYaml(filter: { category: { eq: $category } }, limit: 3) {
      nodes {
        cover {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, width: 30, height: 30)
          }
        }
        coveralt
        h1
        link
      }
    }
    bottom: allItYaml(limit: 3) {
      nodes {
        cover {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, width: 200, height: 200)
          }
        }
        coveralt
        category
        h1
        link
        description
      }
    }
  }
`;
